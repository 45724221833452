var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.have_corporate
      ? _c("div", { staticClass: "well well-sm" }, [
          _c("p", [_vm._v("Данный клиент не отмечен как корпоративный")]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-success add-corporate",
              on: { click: _vm.makeCorp }
            },
            [_vm._v("Сделать клиента корпоративным")]
          )
        ])
      : _c("div", [
          _c(
            "form",
            { staticClass: "form-horizontal" },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-4" },
                  [
                    _c("GenInput", {
                      attrs: {
                        size: 6,
                        "label-size": 2,
                        entity: "corporate",
                        attr: "type",
                        apiDomain: _vm.domain,
                        apiTopic: "client",
                        apiPath: _vm.apiPath
                      },
                      model: {
                        value: _vm.model.type,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "type", $$v)
                        },
                        expression: "model.type"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-8" },
                  [
                    _c("GenInput", {
                      attrs: {
                        size: 4,
                        "label-size": 2,
                        entity: "corporate",
                        attr: "inn_okpo",
                        apiDomain: _vm.domain,
                        apiTopic: "client",
                        apiPath: _vm.apiPath
                      },
                      model: {
                        value: _vm.model.inn_okpo,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "inn_okpo", $$v)
                        },
                        expression: "model.inn_okpo"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "well" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-12" },
                    [
                      _c("GenInput", {
                        attrs: {
                          size: 10,
                          "label-size": 2,
                          entity: "corporate",
                          attr: "full_name",
                          apiDomain: _vm.domain,
                          apiTopic: "client",
                          apiPath: _vm.apiPath
                        },
                        model: {
                          value: _vm.model.full_name,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "full_name", $$v)
                          },
                          expression: "model.full_name"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-8" },
                    [
                      _c("GenInput", {
                        attrs: {
                          size: 8,
                          "label-size": 3,
                          entity: "corporate",
                          attr: "location_legal",
                          name: "location_legal",
                          apiDomain: _vm.domain,
                          apiTopic: "client",
                          apiPath: _vm.apiPath
                        },
                        model: {
                          value: _vm.model.location_legal,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "location_legal", $$v)
                          },
                          expression: "model.location_legal"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-8" },
                    [
                      _c("GenInput", {
                        attrs: {
                          size: 8,
                          "label-size": 3,
                          entity: "corporate",
                          attr: "location_actual",
                          apiDomain: _vm.domain,
                          apiTopic: "client",
                          apiPath: _vm.apiPath
                        },
                        model: {
                          value: _vm.model.location_actual,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "location_actual", $$v)
                          },
                          expression: "model.location_actual"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "well" },
                [
                  _c("GenInput", {
                    attrs: {
                      size: 6,
                      "label-size": 2,
                      entity: "corporate",
                      attr: "bank_account",
                      apiDomain: _vm.domain,
                      apiTopic: "client",
                      apiPath: _vm.apiPath
                    },
                    model: {
                      value: _vm.model.bank_account,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "bank_account", $$v)
                      },
                      expression: "model.bank_account"
                    }
                  }),
                  _vm._v(" "),
                  _c("GenInput", {
                    attrs: {
                      size: 3,
                      "label-size": 2,
                      entity: "corporate",
                      attr: "bank_inn",
                      apiDomain: _vm.domain,
                      apiTopic: "client",
                      apiPath: _vm.apiPath
                    },
                    model: {
                      value: _vm.model.bank_inn,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "bank_inn", $$v)
                      },
                      expression: "model.bank_inn"
                    }
                  }),
                  _vm._v(" "),
                  _c("GenInput", {
                    attrs: {
                      size: 3,
                      "label-size": 2,
                      entity: "corporate",
                      attr: "bank_bik",
                      apiDomain: _vm.domain,
                      apiTopic: "client",
                      apiPath: _vm.apiPath
                    },
                    model: {
                      value: _vm.model.bank_bik,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "bank_bik", $$v)
                      },
                      expression: "model.bank_bik"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("GenInput", {
                attrs: {
                  size: 10,
                  "label-size": 2,
                  entity: "corporate",
                  attr: "service_description",
                  apiDomain: _vm.domain,
                  apiTopic: "client",
                  apiPath: _vm.apiPath
                },
                model: {
                  value: _vm.model.service_description,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "service_description", $$v)
                  },
                  expression: "model.service_description"
                }
              })
            ],
            1
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }