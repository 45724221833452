<template>
  <div class="row">
    <div class="col-md-12">
      <Corporate :uid=state.uid />
    </div>
    <div class="col-md-12">
      <RadixModule path="client/file/file-corporate" :state=state />
    </div>      
  </div>
</template>

<script>
import RadixModule from "RadixModule.vue"
import Corporate from "client/corporate/Corporate.vue"

export default {

  name: "ClientCorporate",
  components: {RadixModule, Corporate},
  
  props: {
    state: Object,
  },  
  
  data()
  {
    return {
    }
  },
 
}
</script>
