var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "well" },
    [
      _c("DynaList", {
        attrs: {
          apiDomain: _vm.domain,
          apiTopic: "client",
          baseUrl: `/client/${_vm.uid}/contact`,
          addText: "Добавить контакт",
          withTitle: true
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(data) {
              return [
                _c("Dictionary", {
                  attrs: {
                    apiDomain: _vm.domain,
                    apiTopic: "client",
                    baseUrl: _vm.makeMediaEnt(data.parent.entity),
                    kinds: _vm.$store.getters.attr(
                      "client",
                      "contact-media",
                      "type"
                    ).options
                  }
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }