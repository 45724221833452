<template>
  <div>
    <div class="well well-sm" v-if="!have_corporate">
      <p>Данный клиент не отмечен как корпоративный</p>
      <button class="btn btn-success add-corporate" @click=makeCorp >Сделать клиента корпоративным</button>
    </div>
    <div v-else>
      <form class="form-horizontal">

        <div class="row">
          <div class="col-md-4">
            <GenInput :size=6 :label-size=2 entity=corporate attr=type v-model="model.type" :apiDomain=domain apiTopic=client :apiPath=apiPath />
          </div>            
          <div class="col-md-8">
            <GenInput :size=4 :label-size=2 entity=corporate attr=inn_okpo v-model="model.inn_okpo" :apiDomain=domain apiTopic=client :apiPath=apiPath />
          </div>            
        </div>

        <div class="well">
        <div class="row">
          <div class="col-md-12">
            <GenInput :size=10 :label-size=2 entity=corporate attr=full_name v-model="model.full_name" :apiDomain=domain apiTopic=client :apiPath=apiPath />
          </div>
        </div>
        
        <div class="row">  
          <div class="col-md-8">
            <GenInput :size=8 :label-size=3 entity=corporate attr=location_legal v-model="model.location_legal" name=location_legal :apiDomain=domain apiTopic=client :apiPath=apiPath />
          </div>
        </div>

        <div class="row">
          <div class="col-md-8">
            <GenInput :size=8 :label-size=3 entity=corporate attr=location_actual v-model="model.location_actual" :apiDomain=domain apiTopic=client :apiPath=apiPath />
          </div>
        </div>
        </div>

        <div class="well">
          <GenInput :size=6 :label-size=2 entity=corporate attr=bank_account v-model="model.bank_account" :apiDomain=domain apiTopic=client :apiPath=apiPath />
          <GenInput :size=3 :label-size=2 entity=corporate attr=bank_inn v-model="model.bank_inn" :apiDomain=domain apiTopic=client :apiPath=apiPath />
          <GenInput :size=3 :label-size=2 entity=corporate attr=bank_bik v-model="model.bank_bik" :apiDomain=domain apiTopic=client :apiPath=apiPath />
        </div>
        
        <GenInput :size=10 :label-size=2 entity=corporate attr=service_description v-model="model.service_description"
          :apiDomain=domain apiTopic=client :apiPath=apiPath />

      </form>
    </div>
  </div>    
</template>

<script>
import darsan from "darsan"
import sysconf from "sysconf"
import SkelInput from "common/visual/SkelInput.vue"
import GenInput from "common/visual/cooper/GenInput.vue"

export default {
  
  name: "Corporate",
  
  components: {SkelInput, GenInput},
  
  props: {
    uid: String,
  },
  
  data() {
    return {
      state: {},
      model: {},
      domain: "",
      have_corporate: false,
      corp: {},
    }
  },
  
  async created()
  {
    this.corp = this.$store.getters.entity("client", "corporate")
  },
  
  methods: {
    async load()
    {
      this.have_corporate = false

      this.domain = sysconf.domainByUid(this.uid) || config.domain
      
      try {
        this.model = await darsan.getm(this.domain, "client", `/client/${this.uid}/corporate`)
        this.have_corporate = true
      }
      catch(err)
      {
        if (err.status != 404) darsan.err(err)
      }
    },
    
    async makeCorp()
    {
      this.model = await darsan.post(this.domain, "client", `/client/${this.uid}/corporate`)
      this.have_corporate = true
    },
  },
  
  computed: {
    apiPath()
    {
      return "/client/"+this.uid+"/corporate"
    },
  },
  
  watch: {
    uid: {
      immediate: true,
      handler(val)
      {
        this.load()
      },
    },
  },
  
}
</script>
